export default {
  "404": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error 404 - Page not found"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website you are looking for has not been found."])}
  },
  "footer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo Wallet"])}
  },
  "login": {
    "new_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New wallet"])},
    "new_wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet name"])},
    "new_wallet_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet password"])},
    "new_wallet_button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create wallet"])},
    "new_wallet_button_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import wallet"])},
    "new_wallet_help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your wallet will be stored in your browser. Wallet password is required to open wallet and see the accounts within the wallet and for signing transactions."])},
    "new_wallet_help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We recommend setting password of length more then 12 characters, using lower case letter, uppercase letter, number and special character."])},
    "open_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open wallet"])},
    "select_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select wallet"])},
    "wallet_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet password"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strength"])},
    "new_wallet_button_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open wallet"])},
    "basic_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic usage"])},
    "tether_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to use Tether  (USDt)"])},
    "source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWallet source code"])}
  },
  "global": {
    "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])},
    "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go home"])},
    "copy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy address"])},
    "copied_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied to clipboard"])},
    "stop_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop camera"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
  },
  "navbar": {
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Wallet"])},
    "toggle_nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle navigation"])},
    "list_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List my accounts"])},
    "new_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New account"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "asset_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create NTF"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "merchant": {
    "make_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment gateway"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "to_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the algorand address"])},
    "please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
    "matching_symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching symbol"])},
    "network_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network fee"])},
    "pay_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay using QR code"])},
    "pay_nativewallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay using algorand wallet"])},
    "pay_webwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay using web wallet"])},
    "cancel_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel payment"])},
    "payment_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment received"])},
    "go_back_to_merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to merchant"])},
    "waiting_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for the payment"])}
  },
  "gateway": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free opensource payment gateway without middleman"])},
    "perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start accepting algo, USDC, USDT or other assets on your website. By accepting cryptocurrency without financial middleman you will increase your sales on your website and increase your profit."])},
    "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works?"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create unique matching symbol, eg. order id"])},
    "redirect_to_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect user to the gateway"])},
    "customer_pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer pays directly to your algorand account"])},
    "customer_redirected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer will be redirected to your website back after successful payment"])},
    "use_your_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your money. You can echange USDT and take USD from the exchange, or you can pay with USDT for other goods or services"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it secure?"])},
    "you_make_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You make the payment details. You setup the successful and cancel URL address. We notify you when the payment is done. You have to check in your backend where you accept the payment that it has been received and you notify the customer."])},
    "opensource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This project is open source. You can clone, modify it, or run in your private environment for commercial purposes"])},
    "just_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment gateway is just HTML website with javascript. No other backend is used other than official algorand nodes."])},
    "no_restrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have AWallet account in order to use the gateway."])},
    "allowed_parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed parameters"])},
    "parameters_perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can set any of these parameters. Only required field is the address where you want to receive your payment and transaction amount. The payment address consits of 2 parts. Payment info, and gateway settings. Both fields are base64 encoded separated by /. Please make sure that you url encode them properly. Payment info is the basic algorand protocol address used for example in the QR codes."])},
    "payment_info_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed parameters"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["use the asset base unit. Eg. 1 ALGO = 1000000"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the amount in microalgos. Eg. 0,001 ALGO = 1000"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network for debug purposes"])},
    "settings_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed settings"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address where to redirect client after successful payment"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address where to redirect client after he clicks cancel payment"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example"])}
  },
  "receive": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive payment"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive payment to address"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about decimal place to QR code"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested label of the receiver address"])},
    "noteeditable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note is editable by user"])}
  },
  "acc_overview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account overview"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make new payment"])},
    "receive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive payment"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "payment_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment gateway"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "amount_without_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount without pending rewards"])},
    "rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rewards"])},
    "pending_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending rewards"])},
    "reward_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward base"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round"])},
    "apps_local_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps local state"])},
    "apps_total_schema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps total schema"])},
    "assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets"])},
    "created_apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created apps"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "multisignature_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisignature threshold"])},
    "multisignature_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisignature addresses"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
    "no_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No transactions found"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "tr_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver rewards"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed round"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "delete_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this account from your wallet?"])},
    "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this account"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account has been deleted"])},
    "asset_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to asset"])}
  },
  "optin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept transfers of asset in account"])},
    "searchById": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I know the asset ID"])},
    "assetId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset ID"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset Name"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find asset"])},
    "assetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about the asset"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "unit_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit name"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimals"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "metadata_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata hash"])},
    "default_frozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default frozen"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator address"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager address"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clawback address"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze address"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve address"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in"])},
    "dialog_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to asset"])},
    "optin_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to pay 0.001 algo to opt in to this asset? After you opt in, you will be able to receive this asset to your account."])},
    "optin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to opt in"])},
    "asset_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset not found"])},
    "asset_opt_in_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully opt in for the asset"])}
  },
  "accounts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts overview"])},
    "no_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No accounts found"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])}
  },
  "newacc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New account"])},
    "create_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create basic account"])},
    "import_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import account"])},
    "create_multisign_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create multisign account"])},
    "watch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch account"])},
    "write_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write down 25 word mnemonic phrase"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal account name"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "multisig_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisignature account can process transactions only if N accounts listed at the account creation sign the transaction."])},
    "select_account_from_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select existing accounts in your wallet"])},
    "add_other_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your friends accounts - one account per line"])},
    "trashold_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select how many accounts are required to sign the transaction"])},
    "create_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to create account, make sure to backup mnemonic phrase. Is it safe to show the phrase now?"])},
    "show_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show mnemonic"])},
    "position_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is word at position n."])},
    "mnemonic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write the mnemonic words in displayed order down, copy or take a picture. Next step will be to confirm some of the words from this phrase and we will save it to the encrypted storage in your browser. It is safe to store this mnomenic and use this address without storing it to the account. Make sure never to lose the mnomenic phrase."])},
    "start_challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start the challenge"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new"])},
    "hide_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide mnemonic"])},
    "drop_phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop phrase"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan from QR"])},
    "create_vanity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create vanity account"])},
    "vanity_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address will start with"])},
    "vanity_mid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address will contain"])},
    "vanity_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address will end with"])},
    "vanity_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of addresses processed"])},
    "vanity_button_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find the address"])},
    "vanity_button_stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop searching"])},
    "vanity_workers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of threads to use"])},
    "vanity_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this account"])},
    "auto_lock_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto lock feature is turned off"])}
  },
  "import": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import wallet"])},
    "wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet name"])},
    "wallet_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet file"])},
    "import_wallet_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import wallet"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can import previously exported wallets. Wallet password stays within the exported file, so you can access it after the import with the same password as before. You can change the password after login from the settings page."])}
  },
  "acc_type": {
    "basic_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic account"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisignature account"])},
    "public_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public account"])}
  },
  "pay": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make payment - from"])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle camera"])},
    "note_is_b64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note is Base64 encoded. If enabled you can post base64 input in the note field and send it as raw data to network."])},
    "selected_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected account"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisignature account"])},
    "create_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create proposal"])},
    "sign_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign & send proposal"])},
    "signature_from_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter signature from your friend here"])},
    "load_multisig_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load multisignature data"])},
    "pay_to_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay to wallet account"])},
    "pay_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay to other account"])},
    "store_other_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is better to store tha account to which you are sending money in the address book"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "review_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review payment"])},
    "review_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please review your payment"])},
    "from_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From account"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay to account"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction details"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FirstRound"])},
    "last_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LastRound"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GenesisID"])},
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
    "to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "signatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signatures"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signed"])},
    "not_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not signed"])},
    "sign_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign with"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign"])},
    "send_to_other_signators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send this data to other signators"])},
    "send_to_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send to the network"])},
    "state_sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending payment to to the network"])},
    "state_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment sent to the network. Tx: "])},
    "state_waiting_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for network confirmation."])},
    "state_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation has been received. Your payment is in the block"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "state_error_not_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment has probably not reached the network. Are you offline? Please check you account"])},
    "set_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set maxium"])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environment"])},
    "mainnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mainnet"])},
    "testnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testnet"])},
    "sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["host"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["token"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet password"])},
    "oldpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password"])},
    "newpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "repeatpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password - repeat"])},
    "backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup wallet"])},
    "backup_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can backup wallet and import to other computer."])},
    "create_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create backup"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the wallet"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strength"])},
    "updated_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password has been updated"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom server settings"])},
    "protocol_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register protocol handler"])},
    "protocol_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register web+algorand:// handler"])},
    "protocol_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully updated the protocol handler"])}
  },
  "transaction": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction detail"])},
    "tr_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction ID"])},
    "tr_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction type"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "note_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raw data"])},
    "note_decoded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decoded data"])},
    "tr_close_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close rewards"])},
    "closing_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing amount"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed round"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
    "first_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First valid"])},
    "genesis_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis id"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis hash"])},
    "intra_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intra round offset"])},
    "last_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last valid"])},
    "payment_tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment transaction"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "close_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close amount"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver rewards"])},
    "round_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round time"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender"])},
    "sender_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender rewards"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature"])},
    "created_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created asset"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset name"])},
    "asset_creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset creator"])},
    "asset_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset manager"])},
    "asset_reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset reserve"])},
    "asset_freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset freeze"])},
    "asset_clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset clawback"])},
    "asset_unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset unit name"])},
    "asset_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset total"])},
    "asset_decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset decimals"])},
    "asset_defaultfrozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset default frozen"])},
    "asset_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset url"])},
    "asset_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset metadata"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])}
  },
  "privacy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What private information about me do you collect?"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None. Besides IP address and browser which is stored in logs for every internet service. We do not collect any form of private information such as your name, email or address."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do you store in cookies?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing. We do not use cookies. We use persistant local storage called indexdb for starage of your wallet."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do you collect analytics information?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do not. We do not use any form of analytics service eg google analytics, so that you can feel safer. The web is purly html with javascript, and we do know your IP address only when you request the server. Depending on your settings and your algod server or indexer, the selected server also may know your IP address."])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does use of this service affect commercials I see?"])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Commercial servers do not have any information from us about any usage. Only way how for example google may know you are using this service is that you access this site from google search."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who provides this service?"])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scholtz & Company, jsa has created this service. Company Id: 51 882 272, VAT ID: SK2120828105, based in Bratislava, Slovakia.  If this is running in official website a-wallet.net, the company is also service provider. The company is not liable for any service, nor any errors in the application."])}
  },
  "assetcreate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New NTF / ASA"])},
    "show_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show advanced form"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friendly name"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator address"])},
    "hide_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide advanced form"])},
    "creator_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create address or import address first"])},
    "default_fronzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frozen : User accounts will need to be unfrozen before transacting"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimals: integer number of decimals for asset unit calculation"])},
    "totalIssuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of this asset available for circulation"])},
    "unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used to display asset units to user"])},
    "assetURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional string pointing to a URL relating to the asset"])},
    "assetMetadataHash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional hash commitment of some sort relating to the asset. 32 character length."])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager can modify manager, reserve, freeze, and clawback account for the asset."])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve address: Specified address is considered the asset reserve (it has no special privileges, this is only informational)"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze address: Specified address can freeze or unfreeze user asset holdings"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clawback address: Specified address can revoke user asset holdings and send them to other addresses"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note set in the create transaction"])},
    "create_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create NFT / ASA"])}
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently asked questions"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the wallet"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo wallet application has been initially created by Scholtz & Company, jsa. Open source, html only, coroporate friendly, and security are the main advantages for its usage."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How is my wallet data secured?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your data is in the browser only. It is stored in persistant index db. Every wallet is encrypted using aes256 with your password. If you make strong enough password, your wallet will never be brute-forced. The password does not leave your computer and resides only in the browser cache. After 5 minutes after the login the cache is destroyed."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does my wallet password go to the internet?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never"])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I change the wallet password?"])},
    "a4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login and access your wallet"])},
    "a4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigate to settings."])},
    "a4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type old password, new password, and confirm new password."])},
    "a4_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit form and your password will be changed."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I move my wallet to other computer?"])},
    "a5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login and access your wallet"])},
    "a5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigate to settings."])},
    "a5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to section backup and save your data to the local file."])},
    "a5_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the other computer go to new wallet, import wallet, and upload the downloaded file to the other browser storage."])},
    "a5_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access the wallet with the same password as before. You can change the password in settings."])},
    "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I check your source code?"])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes. https://github.com/scholtz/wallet/ This wallet was made open source and is free to be used, copied or modified even for commercial purposes. If you are able to participate in the project, please be so kind and create a pull request with your improvement."])},
    "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I help the project?"])},
    "a7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donatation to algorand address P65LXHA5MEDMOJ2ZAITLZWYSU6W25BF2FCXJ5KQRDUB2NT2T7DPAAFYT3U will help the project going. The project has not been funded from algorand inc nor algorand foundation."])},
    "a7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To translate project or help with the translation, please go to  https://github.com/scholtz/wallet/tree/master/src/locales, copy the translation file to your language and make a pull request."])},
    "a7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are a programmer, please do a code review, or help us in any way you think this project should be better."])},
    "q8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to make multisignature account?"])},
    "a8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First create the accounts with the private keys or create public accounts. It is better to give them names you will remember its purpose."])},
    "a8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to create new addrress and select multisignature address."])},
    "a8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all accounts which can sign the transactions and select how  many signatures are minimum for the network to accept the multisig payment."])},
    "a8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After you create the multisignature account, you can access it from the accounts list page."])}
  }
}