<template>
  <PublicLayout>
    <h1>Terms and conditions, Privacy policy, GDPR</h1>
    <ol>
      <li>This website provides services for DAOs running on algorand.</li>
      <li>
        DAO tools service is provided at address www.a-dao.net by the
        <i>Company</i> Scholtz &amp; Company, jsa. Company identifier: 51 882
        272, VAT ID: SK2120828105. Company seat: Švabinského 21, 851 01
        Bratislava, Slovakia.
      </li>
      <li>
        All information provided in this service is property of the
        <i>Company</i>.
      </li>
      <li>
        Responsible person for GDPR purposes is Ludovit Scholtz,
        scholtzandcojsa@gmail.com
      </li>
      <li>Any collected data can be sold or given to third party.</li>
      <li>
        Information collected are: IP address, Time of events, Data provided in
        the forms such as name of the service, physical locations, url
        addresses, email addresses.
      </li>
      <li>
        To delete the data, please click improve data, and add the information
        about the deletion to the description. After review process the data
        will be deleted.
      </li>
      <li>This terms are in force from from 6. july 2021.</li>
    </ol>
  </PublicLayout>
</template>
<script>
import PublicLayout from "../layouts/Public.vue";

export default {
  components: {
    PublicLayout,
  },
};
</script>